
body {
    margin: 0;
    overflow-x: hidden;
  }
  
  #root {
    min-height: 100dvh;
  }
  
  .circle-container {
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:-1;
    overflow: hidden;
  }
  
  .prompt-container {
    position:absolute;
    top:0;
    left:0;
    width:100vw;
    height:100dvh;
    z-index:10;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  
  }
  
  
  .ztop {
    display: block;
    z-index: 10;
  }
    
  .circle {
    position: absolute;
    top: -80vmax;
    left: 40%;
    width: 100vmax; /* Adjust size */
    height: 100vmax; /* Adjust size */
    border-radius: 50%;
    mix-blend-mode: screen;
    filter: blur(80px); /* Adjust blur intensity */
  }
  
  #circle1 {
    background-color: rgba(235, 223, 135, 0.6);
    background-color: color(display-p3 0.92 0.87 0 / 0.5);
  
  }
  
  #circle2 {
    background-color: rgba(0, 200, 255, 0.6);
    background-color: color(display-p3 0 0.78 1.0 / 0.6);
  }
  
  #circle3 {
    background-color: rgba(254, 182, 255, 0.6);
    background-color: color(display-p3 1.0 0.71 1.0 / 0.6);
  }
  
  @keyframes orbit {
    0% {
      transform: rotate(0deg) translateX(20vmin) rotate(0deg);
    }
    100% {
      transform: rotate(360deg) translateX(20vmin) rotate(-360deg);
    }
  }
  
  @keyframes rorbit {
    100% {
      transform: rotate(0deg) translateX(20vmin) rotate(0deg);
    }
    0% {
      transform: rotate(360deg) translateX(20vmin) rotate(-360deg);
    }
  }
  
  @keyframes cursor-blink {
    0% {
      opacity: 1.0;
    }
    50% {
      opacity: 1.0;
    }
    66% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  
  }
  
  .cursor {
    animation: cursor-blink 1.5s infinite;
  }
  
  #circle1 {
    animation: orbit 6s infinite linear; /* Adjust timing */
  }
  #circle2 {
    animation: rorbit 10s infinite linear; /* Adjust timing */
  }
  #circle3 {
    animation: rorbit 8s infinite linear; /* Adjust timing */
  }
  
  @keyframes blear {
    0% {
      filter: blur(0.1vmin);
      transform: scale(1.0);
      opacity: 0.2;
    }
    80% {
      filter: blur(0.1vmin);
      transform: scale(1.0);
      opacity: 0.9;
    }
    90% {
      filter: blur(0.5vmin);
      transform: scale(1.01);
      opacity: 0.6;
    }
    100% {
      filter: blur(0.1vmin);
      transform: scale(1.0);
      opacity: 0.2;
    }
  }

  body {
    background-color: var(--background-color);
    color: var(--text-color);
  }



  * {
    --vermillion: #CD523D;
    --text-color: hsl(var(--hue), 100%, 8%);
    --dim-text-color: hsla(var(--hue), 100%, 10%, 40%);
    --background-color: hsl(var(--hue), 10%, 98%);
    --link-color: #3d66cd;
    --link-hover-color: #214dbb;
    --button-color: var(--vermillion);
    --button-hover-color: var(--vermillion);
  }
  
  #terminal {
    mix-blend-mode: multiply;
  }

  @media (prefers-color-scheme: dark) {
    * {
      --text-color: #fff;
      --dim-text-color: #ffffff88;
      --background-color: #16161d;
      --text-color: hsl(var(--hue), 100%, 94%);
      --dim-text-color: hsla(var(--hue), 100%, 90%, 50%);
      --background-color: hsl(var(--hue), 10%, 10%);
      --vermillion: #ff735a;
      --link-color: #83a6ff;
      --link-hover-color: #bdd0ff;
      --button-color: var(--vermillion);
      --button-hover-color: var(--vermillion);
    }
    #terminal {
      mix-blend-mode: screen;
    }
          
    #circle1 {
      background-color: rgba(235, 223, 135, 0.2);
      background-color: color(display-p3 0.92 0.87 0 / 0.2);
    
    }
    
    #circle2 {
      background-color: rgba(0, 200, 255, 0.2);
      background-color: color(display-p3 0 0.78 1.0 / 0.2);
    }
    
    #circle3 {
      background-color: rgba(254, 182, 255, 0.2);
      background-color: color(display-p3 1.0 0.71 1.0 / 0.2);
    }
  
  }