body {
  margin: 0;
  height: 100dvh;
  font-family: "Monaspace Neon", monospace;
  font-weight: 200;

  font-size: calc(max(3.1415926535vmin, 16px));
  line-height: 133%;
}

@media (max-width: 600px) {
  body {
    font-weight: 300
  }
}

button {
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  line-height: 133%;
}


@font-face {
  font-family: 'Monaspace Krypton';
  font-weight: 1 999;
  src: url('/public/fonts/MonaspaceKryptonVarVF[wght,wdth,slnt].woff2') format('woff2');
}
@font-face {
  font-family: 'Monaspace Neon';
  font-weight: 1 999;
  src: url('/public/fonts/MonaspaceNeonVarVF[wght,wdth,slnt].woff2') format('woff2');
}
@font-face {
  font-family: 'Monaspace Xenon';
  font-weight: 1 999;
  src: url('/public/fonts/MonaspaceXenonVarVF[wght,wdth,slnt].woff2') format('woff2');
}